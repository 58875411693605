<template>
    <div class="newMemberReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">办卡日期：</div>
                        <div class="from-input">
                            <el-date-picker class="from-date"
                                v-model="businessHours"
                                type="daterange"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="defaultTime"
                            > </el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">卡状态：</div>
                        <div class="from-input" style="width: 180px;">
                            <el-select v-model="cardStatus" multiple  collapse-tags @removeTag="()=>cardStatus=[]">
                                <el-option :value="1" label="启用"></el-option>
                                <el-option :value="2" label="挂失"></el-option>
                                <el-option :value="3" label="停用"></el-option>
                                <el-option :value="4" label="退卡"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                     <el-checkbox v-model="isLocalMember" label="仅显示本店办卡会员" size="large" :disabled="!isViewOtherDoorMember"></el-checkbox>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="from-input search-input"><input type="text" v-model="selectContent"  placeholder="手机号，卡号，客户姓名模糊检索" /></div>
                        <div class="search-flex">
                            <div class="bnt-search" @click="GetMemberNewGenCount()" >查询</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
        </div>
        <div class="content-box">
            <div class="table-box">
                <el-table class="el-table--scrollable-y" ref="tableEl" 
                    border
                    :data="tableListPage"  
                    v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" >
                    <el-table-column fixed type="index" label="序号" width="42">
                        <template #default="scope">
                            {{scope.$index+pagesize*(currentPage-1)+1}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="Cstm_Name" label="客户姓名" min-width="106" align="left"></el-table-column> 
                    <el-table-column prop="GENDERName" label="性别" min-width="50"></el-table-column>
                    <el-table-column prop="Card_No" label="卡号" min-width="100" align="left"></el-table-column>
                    <el-table-column prop="Cstm_Mobile" label="手机号" min-width="100" align="left"></el-table-column> 
                    <el-table-column prop="BIRTHDAY" label="生日" min-width="80" align="left"></el-table-column>
                    <el-table-column prop="Card_KindName" label="等级" min-width="70"></el-table-column>
                    <el-table-column prop="SaleCard_User" label="销售员" min-width="80" align="left"></el-table-column>
                    <el-table-column prop="IssueCard_Time" label="办卡时间" min-width="125" align="left"></el-table-column>
                    <el-table-column prop="IssueCard_User" label="办卡人" min-width="80" align="left"></el-table-column>
                    <el-table-column prop="IssueDoor_Name" label="办卡门店" min-width="100" align="left"></el-table-column>
                    <el-table-column prop="ValidDate" label="卡有效期" min-width="80" align="left"></el-table-column>
                    <el-table-column prop="Remark" label="备注" min-width="100" align="left"></el-table-column>
                    <el-table-column prop="Card_StatusName" label="状态" min-width="80"></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                :data="tableList"
                @page-change="(list)=>tableListPage=list" 
                :pageSizes="[20,30,50,100]"
                :pageSize="pagesize"
                 @size-change="(size)=>pagesize=size"></table-page-btn>
        </div>
     </div>
</template>

<script> 
import { getDiffDay } from '/src/common'
/**会员新增统计报表 */
export default {
    name:'cardRechargeReport',
    data(){
        return {
            pagesize:20,//每页的数据条数
            currentPage:1,//默认开始页面
            tableListPage:[],
            //检索用
            selectContent:"",
            /**表格数据 */
            tableList:[],
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            //卡状态
            cardStatus: null,
            //仅显示本店办卡会员
            isLocalMember:true,
            //查看非本店新增会员权限
            isViewOtherDoorMember:false
        }
    },
    mounted(){
        //查看非本店新增会员权限
        this.isViewOtherDoorMember=this.$cacheData.SysProjects?.get()?.find(t=>t.Project_Key=="Member")
            ?.SysProjectGroups.find(t=>t.Group_Key=='Member_CloudPos')
            ?.SysProjectItems?.find(t=>t.ProjectItem_Key=='Card-Operate')
            ?.SysProjectSubItems?.find(t=>t.ProjectSubItem_Key=='ViewOtherDoorMember')?.Permission;
    },
    methods:{
        /**Excel导出 */
        exportExcel(){
            if(this.$refs.tableEl){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.tableEl,
                    titleName:"会员新增统计报表",
                    list:this.tableList
                })
            }
        },
        /**获取会员新增统计 */
        GetMemberNewGenCount(){
            let userInfo=this.$auth.getUserInfo();
            let time = JSON.parse(JSON.stringify(this.businessHours));
            
            if(getDiffDay(time[0],time[1])>90){
                this.$message.warning("最多只可查询90天的数据!");
                return;
            }
            let param={
                User_ID:userInfo?.User_ID,
                Card_Statuss:this.cardStatus.toString(), //卡状态，多选状态以逗号分割，空代表全部 1启用 2挂失 3停用 4退卡
                IsSelfDoor:this.isLocalMember==true?1:0,//仅显示本店 0否 1是
                QueryValue:this.selectContent,//检索信息 用于手机、卡号、姓名模糊检索
                BRpt_Date: new Date(time[0]).Format("yyyy-MM-dd"),//办卡结束日期 范围开始日期
                ERpt_Date:new Date(time[1]).Format("yyyy-MM-dd"),//办卡结束日期 范围结束日期
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberNewGenCount",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    console.log(d)
                    this.tableList = d.ResponseBody?.MemberNewGenDetails||[];
                    this.currentPage=1;
                }else{
                     this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e);
                console.log(e);
            })
        },
        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) { // 只找第一列放合计
                    sums[index] = '合计：'
                    return
                }else if (index===4||index===5||index===6||index ===7||index===8||index===9 ||index===15) {
                    const values = data.map(item => Number(item[column.property]))
                    if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return  Number(Number(prev)+Number(curr)).toFixed(2)
                        } else {
                            return  Number(prev).toFixed(2)
                        }
                    },0)} else {
                        sums[index] = 'N/A'
                        }
                    } else {
                        sums[index] = '--'
                        }
                    })
                return sums
        }
    },
}
</script>

<style lang="scss">
@import './newMemberReport.scss'
</style>